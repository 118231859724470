import {Component, OnInit} from '@angular/core';
import { MessageService } from 'primeng/api';
import { PaymentService } from 'src/services/payment.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [MessageService]
})
export class CheckoutComponent implements OnInit {

  constructor(
    private paymentService: PaymentService) {}

  async ngOnInit(): Promise<any> {

  }
}
