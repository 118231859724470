<div class="planosWrapper">
    <div class="banner">
        <div class="logo">
            <img src="./assets/img/logo-alphatime-branca.png" alt="">
        </div>
        <div class="texto">
            Escolha o seu plano e <span>ganhe tempo e dinheiro</span> com a <span>gestão produtiva</span> do ponto da sua equipe.
        </div>
        <img src="./assets/img/icon.png" alt="" class="icon">
        <img src="./assets/img/modelo.png" alt="" class="modelo">
    </div>
    <router-outlet></router-outlet>
</div>
