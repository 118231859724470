import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RegisterComponent} from './register/register.component';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {ProductComponent} from './product/product.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {InputMaskModule} from 'primeng/inputmask';
import {WelcomeComponent} from './welcome/welcome.component';
import {CanceledComponent} from './canceled/canceled.component';
import {MessageService} from 'primeng/api';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {COGNITO} from '../environments/environment';
import {Amplify} from 'aws-amplify';
import {LayoutModule} from '../layout/layout.module';
import {SelectButtonModule} from 'primeng/selectbutton';
import {CheckboxModule} from 'primeng/checkbox';
import {NgxMaskModule} from 'ngx-mask';
import {ContactComponent} from './contact/contact.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {RadioButtonModule} from 'primeng/radiobutton';

Amplify.configure({
    Auth: {
        identityPoolId: COGNITO.identityPoolId,
        region: COGNITO.region,
        userPoolId: COGNITO.userPoolId,
        userPoolWebClientId: COGNITO.userPoolWebClientId
    }
});

@NgModule({
    declarations: [
        AppComponent,
        RegisterComponent,
        ProductComponent,
        CheckoutComponent,
        WelcomeComponent,
        CanceledComponent,
        ContactComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ToastModule,
        LayoutModule,
        SelectButtonModule,
        ReactiveFormsModule,
        InputMaskModule,
        CheckboxModule,
        DialogModule,
        DynamicDialogModule,
        NgxMaskModule.forRoot(),
        ProgressSpinnerModule,
        RadioButtonModule
    ],
    providers: [MessageService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
