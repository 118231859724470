import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {CognitoUser, CognitoUserSession, ISignUpResult} from 'amazon-cognito-identity-js';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public async signUp(user: any): Promise<ISignUpResult> {
    const {password, email, name, profile} = user;
    return await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
        profile
      },
    });
  }

  public async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession();
  }

  public async getCurrentAuthenticatedUser(): Promise<CognitoUser> {
    return await Auth.currentAuthenticatedUser();
  }

  public isValidToken(token: string): boolean {
    if (!token) {
      return false;
    }

    const tokenInfo = this.getTokenInfo(token);

    return !this.isExpiredToken(tokenInfo) && this.tokenContainsAmazonInfo(tokenInfo);
  }

  public getTokenInfo(token: string): any {
    const base64Url = token.split('.')[1];

    return JSON.parse(
      Buffer.from(base64Url, 'base64').toString('ascii')
    );
  }

  public isExpiredToken(tokenInfo: any): boolean {
    if (!tokenInfo.exp || !tokenInfo.auth_time) {
      return false;
    }

    return Date.now() < tokenInfo.exp;
  }

  public tokenContainsAmazonInfo(tokenInfo: any): boolean {
    return tokenInfo.iss && tokenInfo.sub && tokenInfo.aud;
  }

  public getTokenEmail(token: string): string {
    const tokenInfo = this.getTokenInfo(token);

    return tokenInfo.email;
  }
}
