import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {SERVER_URL} from '../environments/environment';
import HttpEmployerResponse from '../model/employee';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private httpClient: HttpClient) {
  }

  /**
   *  Find Employer By Email
   */
  public findByEmail(email: string, jwt: string): Observable<HttpEmployerResponse> {
    const headers = new HttpHeaders().set('Authorization', jwt);

    return this.httpClient.get<HttpEmployerResponse>(`${SERVER_URL}/employee/email/${email}`, {headers});
  }

}
