import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {SERVER_URL} from '../environments/environment';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private httpClient: HttpClient) {
  }

  /**
   *  Company Register
   *
   */
  public sendContact(data: any): Observable<any> {
    return this.httpClient.post(`${SERVER_URL}/notifications/contact`, data, { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': '6Z9tNsDftg2YIItddgDpu6QQ0PvrHzCi51oWChKp' }) });
  }

}
