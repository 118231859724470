<p-toast></p-toast>

<img src="./assets/img/imagem-c.png" class="imagem-c" alt="">
<img src="./assets/img/imagem-a.png" class="imagem-a" alt="">
<img src="./assets/img/imagem-b.png" class="imagem-b" alt="">
<div class="logo-container">
    <img src="./assets/img/logo-descrition.png" class="logo-descrition" alt="">
</div>
<img src="./assets/img/imagem-d.png" class="imagem-d" alt="">

<div class="conteudo d-flex justify-content-end align-items-center">
    <div class="conteudo-form">
        <div class="extra">
            <span>Já tem uma conta? </span>
            <a (click)="gotoDashboardLogin()">Entre aqui!</a>
        </div>
        <h1 (click)="fillForm()" class="text-center"  style="color: white;">Criar uma conta</h1>
        <form [formGroup]="formRegister" (ngSubmit)="register()" style="width: 100%;">
            <div class="form-group">
                <label>Nome</label>
                <input type="text" formControlName="name" placeholder="Digite aqui seu nome" required>
                <div class="invalid-feedback" *ngIf="formRegister.get('name')?.hasError('required')
                                                       && formRegister.get('name')?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> Nome é um campo obrigatório
                </div>
            </div>
            <div class="form-group">
                <label>E-mail</label>
                <input type="email" placeholder="Digite aqui seu e-mail" formControlName="email">
                <div class="invalid-feedback" *ngIf="!formRegister.controls['email']?.valid
                                                       && formRegister.controls['email']?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> Email está inválido
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>CNPJ</label>
                    <input type="text" name="cnpj" placeholder="Digite aqui o CNPJ" mask="00.000.000/0000-00"
                           formControlName="cnpj">
                    <div class="invalid-feedback" *ngIf="!formRegister.controls['cnpj']?.valid
                                                       && formRegister.controls['cnpj']?.touched">
                        <i class="bi bi-exclamation-octagon-fill"></i> CNPJ é um campo obrigatório
                    </div>
                    <div class="invalid-feedback" *ngIf="formRegister.hasError('CNPJNotValid')
                                                       && formRegister.controls['cnpj']?.touched">
                        <i class="bi bi-exclamation-octagon-fill"></i> CNPJ está inválido
                    </div>
                </div>
                <div class="form-group col-md-6" >
                    <label>Celular</label>
                    <input type="text" name="phone" placeholder="(DDD) 99999-9999" mask="(00) 0 0000-0000"
                           formControlName="phone">
                    <div class="invalid-feedback" *ngIf="formRegister.get('phone')?.hasError('required')
                                                       && formRegister.get('phone')?.touched">
                        <i class="bi bi-exclamation-octagon-fill"></i> Celular é um campo obrigatório
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label>Nome da empresa</label>
                <input type="text" placeholder="Digite aqui" formControlName="companyName">
                <div class="invalid-feedback" *ngIf="formRegister.get('companyName')?.hasError('required')
                                                      && formRegister.get('companyName')?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> Nome da Empresa é um campo obrigatório
                </div>
                <div class="invalid-feedback" *ngIf="formRegister.get('companyName')?.hasError('maxlength')
                                                       && formRegister.controls['companyName']?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> O nome da empresa deve ter no máximo 120 caracteres
                </div>
            </div>
            <div class="form-group">
                <div class="inner-addon right-addon">
                    <label>Senha</label>
                    <i [class]="showHidePassword ? 'bi bi-eye-slash' : 'bi bi-eye'"
                       (click)="showHidePassword = !showHidePassword"></i>
                    <input type="password" placeholder="Digite uma senha com no mínimo 8 caracteres"
                           formControlName="password" [type]="showHidePassword ? 'password' : 'text'">
                </div>
                <div class="invalid-feedback" *ngIf="formRegister.get('password')?.hasError('required')
                                                       && formRegister.get('password')?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> Senha é um campo obrigatório
                </div>
                <div class="invalid-feedback" *ngIf="formRegister.get('password')?.hasError('minlength')
                                                       && formRegister.controls['password']?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> A senha deve ter no mínimo 8 caracteres
                </div>
                <div class="invalid-feedback" *ngIf="formRegister.get('password')?.hasError('maxlength')
                                                       && formRegister.controls['password']?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> A senha deve ter no máximo 24 caracteres
                </div>
            </div>
            <div class="form-group">
                <div class="inner-addon right-addon">
                    <label>Confirme a senha</label>
                    <i [class]="showHideConfirmPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"
                       (click)="showHideConfirmPassword = !showHideConfirmPassword"></i>
                    <input type="password" placeholder="Digite aqui sua senha novamente" formControlName="confirmPassword"
                           [type]="showHideConfirmPassword ? 'password' : 'text'">
                </div>
                <div class="invalid-feedback" *ngIf="formRegister.hasError('passwordsNotEqual')
                                                       && formRegister.controls['confirmPassword'].touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> Senhas não conferem
                </div>
                <div class="invalid-feedback" *ngIf="formRegister.get('confirmPassword')?.hasError('minlength')
                                                       && formRegister.controls['confirmPassword']?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> A senha deve ter no mínimo 8 caracteres
                </div>
                <div class="invalid-feedback" *ngIf="formRegister.get('confirmPassword')?.hasError('maxlength')
                                                       && formRegister.controls['confirmPassword']?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> A senha deve ter no máximo 24 caracteres
                </div>
            </div>

            <div class="form-group form-check" style="margin-left: 0px !important;">
                <a (click)="handleShowModalTerms()" style="cursor: pointer;color: #33FF99 !important;">Leia aqui os Termos de Uso</a><br>
                <p-checkbox formControlName="termsAccepted" [(ngModel)]="checked" [binary]="true" inputId="binary" class="form-check-input"></p-checkbox>
                <label class="form-check-label">Eu Concordo Com os Termos de Uso</label>

                <div class="invalid-feedback" *ngIf="checked === false
                                                       && formRegister.controls['termsAccepted']?.touched">
                    <i class="bi bi-exclamation-octagon-fill"></i> Você deve concordar com os termos de uso
                </div>
                <div class="invalid-feedback" *ngIf="formRegister.hasError('TermsAcceptedNotValid')">
                </div>
            </div>

            <div class="form-group">
                <div class="btn-save-container">
                    <button type="submit" [disabled]="!formRegister.valid">
                        <div *ngIf="loading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="sr-only">Salvando...</span>
                        </div>
                        <span *ngIf="!loading">Cadastrar</span>
                    </button>
                </div>
            </div>
        </form>
        <div id="modal">
            <p-dialog
            [(visible)]="showModalTerms"
            >
            <ng-template pTemplate="header">
                <div class="d-flex justify-content-center">
                    <b style="font-size: 22px !important; color: #0008; text-align: center;">Termos de uso da AlphaTime</b>
                </div>
            </ng-template>
                A AlphaTime fornece um serviço personalizado de assinatura que permite aos nossos assinantes fazer a gestão do tempo de trabalho de seus funcionários, via dados transmitidos pela Internet para os aparelhos conectados.

                Estes Termos de Uso regulam a utilização do serviço provido pela AlphaTime e usados nestes Termos de Uso, os termos “serviço AlphaTime”, “nosso serviço” ou “o serviço” significam o serviço personalizado fornecido pela AlphaTime, incluindo todos os recursos e funcionalidades, oferecidos pelo aplicativo, o site e as interfaces do usuário, assim como todo o conteúdo e software associados ao serviço.

                Assinatura

                1.1. Sua assinatura AlphaTime continuará até que seja cancelada. Para utilizar o serviço AlphaTime, você precisa ter acesso à Internet e um aparelho compatível com a AlphaTime, bem como fornecer uma ou mais Formas de Pagamento. “Forma de Pagamento” refere-se a uma forma de pagamento atualizada, válida e aceitável, que poderá ser atualizada periodicamente. A menos que cancele a assinatura antes da data de cobrança, você nos autoriza a cobrar a taxa de assinatura do próximo ciclo de faturamento usando a sua Forma de Pagamento (consulte “Cancelamento” abaixo).

                1.2. Poderemos oferecer vários planos de assinatura, inclusive planos promocionais especiais. Alguns planos de assinatura podem possuir condições e limitações diferentes, que serão reveladas no momento de sua inscrição ou em outras comunicações disponibilizadas a você. Você pode encontrar detalhes específicos a respeito da sua assinatura da AlphaTime acessando o nosso site e clicando no link “Conta”, exibido na parte superior de todas as páginas do site da AlphaTime abaixo do nome de seu perfil.

                Períodos de utilização gratuita

                2.1. Sua assinatura AlphaTime poderá começar com um período de utilização gratuita. A duração do período de utilização gratuita da assinatura será especificada durante o fluxo de assinatura e tem a finalidade de permitir que novos assinantes e determinados ex-assinantes experimentem o serviço.

                2.2. A elegibilidade ao período de utilização gratuita é determinada pela AlphaTime a seu exclusivo critério, sendo que poderemos limitar tal elegibilidade ou a sua duração para evitar abusos dos períodos de utilização gratuita. A AlphaTime se reserva o direito de revogar a utilização gratuita e suspender sua conta se determinarmos que você não é elegível. A AlphaTime poderá usar informações como identificação do aparelho, forma de pagamento ou e-mail da conta usados em uma assinatura AlphaTime atual ou recente para determinar a elegibilidade. Para combinação com outras ofertas, restrições poderão ser aplicáveis.

                2.3. A AlphaTime cobrará, de acordo com a sua Forma de Pagamento escolhida, o preço da assinatura referente ao próximo ciclo de faturamento ao final do período de utilização gratuita, a não ser que você cancele sua assinatura antes do final do referido período. Para consultar o preço da assinatura e a data de término do seu período de utilização gratuita, acesse o site da AlphaTime e clique no link “Detalhes de cobrança” na página “Conta”.

                Faturamento e cancelamento

                3.1. Ciclo de faturamento. O preço da assinatura do serviço AlphaTime e todos os outros encargos incorridos por você em relação ao uso do serviço, tais como impostos e possíveis taxas de transação, serão cobrados, de acordo com a Forma de Pagamento escolhida, na data de cobrança específica indicada na página “Conta”. A duração do ciclo de faturamento dependerá do tipo de assinatura escolhido quando você assinou o serviço. Em alguns casos, a data de pagamento poderá ser alterada, por exemplo, se a Forma de Pagamento não puder ser cobrada ou se a sua assinatura paga começar em um dia que não existe em um determinado mês. Visite o site da AlphaTime e clique em “Detalhes de cobrança” na página “Conta” para ver a data do seu próximo pagamento. A AlphaTime poderá solicitar antecipadamente uma autorização do valor de sua assinatura ou cobranças relacionadas ao serviço de sua Forma de Pagamento de várias formas, inclusive a autorização de até aproximadamente um ano de serviço, no ato de sua inscrição, se for essa a modalidade escolhida. Em certos casos, seu saldo ou limite de crédito poderá ser reduzido para refletir a autorização durante seu o período de utilização gratuita.

                3.2. Formas de Pagamento. Para utilizar o serviço AlphaTime, você precisa fornecer uma ou mais Formas de Pagamento. Você nos autoriza a cobrar de qualquer Forma de Pagamento associada à sua conta caso a sua Forma de Pagamento principal seja negada ou não esteja mais disponível para nós para o pagamento do valor da assinatura e você concorda que as Formas de Pagamento disponíveis tanto para operações de débito como de crédito poderão ser processadas de ambas as formas. Você continua responsável por todos os valores não pagos. Se um pagamento não for liquidado com sucesso, devido à perda de validade, insuficiência de fundos ou qualquer outro motivo, e você não cancelar a sua conta, poderemos suspender o seu acesso ao serviço até que consigamos fazer a cobrança de uma Forma de Pagamento válida. Para algumas Formas de Pagamento, as instituições financeiras e/ou os prestadores de serviço de meios de pagamento poderão cobrar determinadas taxas, como taxas sobre transações financeiras internacionais ou outras taxas relacionadas à Forma de Pagamento. A cobrança de impostos locais pode variar de acordo com a Forma de Pagamento usada. Consulte a instituição financeira da sua Forma de Pagamento para obter mais detalhes.

                3.3. Atualização das Formas de Pagamento. Você pode atualizar as suas Formas de Pagamento acessando a página “Conta”. Nós também podemos atualizar as suas Formas de Pagamento com informações fornecidas pelas respectivas instituições financeiras, instituições de pagamento e/ou pelos prestadores de serviços de meios de pagamento. Após qualquer atualização, você nos autoriza a continuar a cobrar a(s) respectiva(s) Forma(s) de Pagamento.

                3.4. Cancelamento. Você pode cancelar sua assinatura AlphaTime a qualquer momento e você continuará a ter acesso ao serviço AlphaTime até o fim do período de faturamento. Na extensão permitida pelas leis aplicáveis, os pagamentos não são reembolsáveis e a AlphaTime não oferece reembolsos ou créditos por períodos de assinatura utilizados parcialmente ou por conteúdo AlphaTime não utilizado. Para cancelar, acesse a página “Conta” e siga as instruções de cancelamento. Se você cancelar sua assinatura, sua conta será automaticamente encerrada ao fim do período de cobrança em andamento. Para verificar quando sua conta será encerrada, clique na opção “Detalhes de cobrança” da página “Conta”.


                3.5. Alterações de preços e planos de assinatura. De tempos em tempos, poderemos alterar nossos planos de assinatura e os preços de nosso serviço. Os valores de assinatura poderão ser revisados periodicamente para melhor adequação ao contínuo aprimoramento de nosso serviço. Nós também poderemos ajustar o valor de sua assinatura anualmente, ou com maior frequência, conforme permitido pela legislação vigente, de acordo com a inflação estabelecida pelo Índice Geral de Preços do Mercado (IGP-M), publicado pela Fundação Getúlio Vargas, ou outro índice equivalente aplicável ao serviço AlphaTime. Quaisquer alterações de preço ou alterações nos seus planos de assinatura serão aplicadas aos ciclos de faturamento subsequentes ao envio da notificação para você.

                Serviço AlphaTime

                4.1. Você deve ter 18 anos ou ser maior de idade em seu estado, território ou país para ser assinante do serviço AlphaTime. Menores de idade somente podem usar o serviço sob a supervisão de um adulto.

                4.2. O serviço AlphaTime e todo o conteúdo visualizado por intermédio do serviço AlphaTime destinam-se exclusivamente para uso profissional e empresarial, não podendo ser compartilhados com pessoas estranhas à relação empregador/empregado. Durante sua assinatura AlphaTime, a AlphaTime concede a você um direito limitado, não exclusivo e intransferível para acessar o serviço AlphaTime e fazer a gestão do tempo de trabalho de seu(s) funcionário(s), por meio das funcionalidades existentes no sistema. Exceto pelo descrito acima, nenhum outro direito, titularidade ou participação lhe é concedido. Você concorda em não utilizar o serviço em exibições públicas.

                4.3. Você pode utilizar o serviço da AlphaTime, primordialmente na cidade do país onde fez sua inscrição e somente nas áreas geográficas onde oferecermos nosso serviço. O número de funcionários que você poderá cadastrar para fazer a gestão do tempo de trabalho depende do plano de assinatura escolhido e está especificado na página “Conta”.

                4.4. O serviço AlphaTime é atualizado regularmente. Além disso, diferentes aspectos do serviço são testados de forma contínua, incluindo o site, dashboard, as interfaces de usuário, os recursos promocionais e a disponibilidade dos serviços da AlphaTime. A qualquer momento, você pode desativar a participação em testes. Para isso, basta acessar a página “Conta” e alterar as configurações em “Participação em testes”.

                4.5. Você concorda em usar o serviço AlphaTime, incluindo todos os recursos e funcionalidades associadas de acordo com as leis, regras e regulamentos aplicáveis ou outras restrições de uso do serviço ou conteúdo previstas nas mesmas. Você também concorda em não arquivar, reproduzir, distribuir, modificar, exibir, executar, publicar, licenciar ou criar trabalhos derivados, colocar à venda ou utilizar (exceto nas formas expressamente autorizadas por estes Termos de Uso) o conteúdo e as informações contidas ou obtidas do serviço AlphaTime ou por meio deste. Você também concorda em não contornar, remover, alterar, desativar, degradar ou adulterar quaisquer das proteções de conteúdo do serviço AlphaTime, usar qualquer robô, spider, scraper ou outros meios automatizados para acessar o serviço AlphaTime, descompilar, executar engenharia reversa ou desmembrar qualquer software ou outros produtos ou processos acessíveis pelo serviço AlphaTime, inserir qualquer código ou produto ou manipular o conteúdo do serviço AlphaTime de qualquer forma ou usar métodos de data mining, coleta de dados ou extração de dados. Além disso, você concorda em não fazer upload, publicar, enviar por e-mail, comunicar ou transmitir de qualquer forma qualquer material designado para interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento de telecomunicações associado ao serviço AlphaTime, incluindo vírus de software, código, arquivos ou programas. A AlphaTime poderá cancelar ou restringir seu uso do serviço se você violar estes Termos de uso ou se envolver no uso ilegal ou fraudulento do serviço.

                4.6. A qualidade do serviço da AlphaTime pode variar de aparelho para aparelho e pode ser afetada por diversos fatores, incluindo sua localização, a largura de banda disponível e a velocidade da sua conexão com a Internet. Você se responsabiliza por todas as tarifas de acesso à Internet. Por favor, consulte seu provedor de Internet para obter informações sobre os custos de utilização de dados. O tempo necessário para começar a utilizar os serviços da AlphaTime poderá variar de acordo com uma série de fatores, incluindo a sua localização, a largura de banda disponível no momento e as configurações do aparelho compatível com a AlphaTime utilizado.



                4.8. O software da AlphaTime foi desenvolvido pela ou para a AlphaTime e foi projetado para permitir a gestão do tempo de trabalho de empregados e outros serviços derivados, em aparelhos compatíveis com a AlphaTime. Esse software poderá variar conforme o aparelho. A funcionalidade e os recursos também poderão variar segundo o aparelho utilizado. Você concorda que o uso do serviço poderá exigir softwares de terceiros, sujeito a licenças de terceiros. Você concorda que poderá receber automaticamente versões atualizadas dos softwares da AlphaTime e de terceiros relacionados.

                Senhas e acesso à conta. O assinante que criou a conta AlphaTime e cuja Forma de Pagamento é cobrada pelo serviço (“Proprietário da Conta”) possui acesso e controle sobre a conta AlphaTime e deve ser o proprietário dos aparelhos compatíveis com a AlphaTime usados para acessar o nosso serviço, sendo responsável por qualquer atividade que ocorre em sua conta AlphaTime. Para manter o controle sobre a conta e evitar que qualquer pessoa possa acessá-la (o que incluiria informações do(s) empregado(s) da conta), o Proprietário da Conta deve manter o controle sobre os aparelhos compatíveis com a AlphaTime utilizados para acessar o serviço e não revelar a ninguém a senha ou os detalhes da Forma de Pagamento associada à conta. Você é responsável por atualizar e manter exatas as informações fornecidas à AlphaTime relativas à sua conta. A AlphaTime poderá cancelar ou suspender a sua conta para proteger você, a AlphaTime ou nossos parceiros contra falsidade ideológica ou outras atividades fraudulentas.

                Garantias e isenção de responsabilidades. O SERVIÇO ALPHATIME É FORNECIDO “NO ESTADO EM QUE SE ENCONTRA”, SEM GARANTIAS OU CONDIÇÕES. ESPECIFICAMENTE, O NOSSO SERVIÇO PODE NÃO ESTAR LIVRE DE INTERRUPÇÕES OU ERROS. VOCÊ RENUNCIA A TODAS E QUAISQUER INDENIZAÇÕES POR DANOS INDIRETOS, MORAIS E MATERIAIS CONTRA A ALPHATIME. A ALPHATIME NÃO É RESPONSÁVEL PELA QUALIDADE DE SUA CONEXÃO COM DE INTERNET, NEM POR EVENTUAIS RUPTURAS NO FORNECIMENTO DO SERVIÇO GLOBAL DE INTERNET OU PELOS PROVEDORES DESSES SERVIÇOS. Estes Termos não limitarão nenhuma garantia ou direitos não renunciáveis de proteção ao consumidor, aos quais você possa ter direito de acordo com as leis de seu país de residência.



                Diversos

                7.1. Legislação aplicável. Estes Termos de uso devem ser regidos por e interpretados de acordo com a legislação do Brasil.

                7.2. Materiais não solicitados. A AlphaTime não aceita materiais ou ideias não solicitados de serviços da AlphaTime e não é responsável pela semelhança de seu serviço ou programação em qualquer software.

                7.3. Atendimento ao cliente. Para obter mais informações sobre o serviço AlphaTime, suas funcionalidades e recursos, ou caso precise de ajuda com sua conta, por favor, acesse o Centro de ajuda AlphaTime em nosso site. Em alguns casos, a melhor opção para o atendimento ao cliente é utilizar uma ferramenta de acesso remoto que dá acesso total ao seu computador. Se você não quiser que tenhamos esse acesso, você não deve fornecer seu consentimento para o uso da ferramenta de suporte por acesso remoto. A AlphaTime ajudará você de outras formas. No caso de quaisquer conflitos entre estes Termos de Uso e informações fornecidas pelo atendimento ao cliente, estes Termos de uso prevalecerão.

                7.4. Subsistência. Se qualquer disposição ou disposições destes Termos de uso forem consideradas inválidas, ilegais ou não aplicáveis, a validade, legalidade e aplicabilidade das demais disposições devem permanecer em pleno vigor.

                7.5. Alterações dos termos de uso e cessão. A AlphaTime poderá alterar estes Termos de uso periodicamente. Notificaremos você com pelo menos 30 dias de antecedência antes que as alterações se apliquem a você. A qualquer momento, a AlphaTime poderá ceder ou transferir o nosso contrato com você, inclusive nossos direitos e obrigações associados. Você concorda em cooperar com a AlphaTime nessas cessões ou transferências.

                7.6. Comunicações eletrônicas. Enviaremos informações relacionadas à sua conta (por exemplo, autorizações de pagamento, cobranças, alterações de senha ou Forma de Pagamento, mensagens de confirmação, notificações) somente em formato eletrônico como, por exemplo, por meio de e-mails para o endereço fornecido durante a inscrição.

                Última atualização: 01 de fevereiro 2022.


                Declaração de Privacidade

                Esta Declaração de Privacidade explica as práticas da AlphaTime, incluindo as suas escolhas como cliente, referentes à coleta, utilização e divulgação de certas informações, incluindo suas informações pessoais.

                Como contatar a AlphaTime
                Caso tenha dúvidas gerais sobre sua conta ou sobre como entrar em contato com o atendimento ao cliente, acesse nosso Centro de ajuda online em https://help.AlphaTime.net. Para questões específicas sobre esta Declaração de Privacidade, incluindo a utilização de informações pessoais, cookies e outras tecnologias semelhantes, entre em contato com o nosso Diretor de Proteção de Dados/Divisão de Privacidade por email no endereço privacy@AlphaTime.net.

                A AlphaTime é a controladora das suas informações pessoais. Se você entrar em contato solicitando assistência, para sua segurança e da AlphaTime, poderá ser preciso autenticar sua identidade antes de atender ao seu pedido.

                Coleta de informações
                A AlphaTime recebe e armazena informações sobre você, tais como:
                Informações que você nos fornece: a AlphaTime coleta as informações que você nos fornece, que incluem:
                Seu nome, e-mail, endereço ou código postal, forma(s) de pagamento e número de telefone. Em certos países, coletamos um número de identificação nacional para fins de cobrança e cumprimento das leis fiscais. As informações são coletadas de diversas formas, incluindo quando você as fornece ao usar o serviço, interage com o serviço de atendimento ao cliente da AlphaTime ou participa em pesquisas ou promoções de marketing;
                Informações quando você opta por definir suas configurações (inclusive configuradas na seção “Conta” no nosso site) ou, de outra forma, fornece informações à AlphaTime, seja por intermédio do serviço AlphaTime ou outros.
                Informações coletadas automaticamente pela AlphaTime: a AlphaTime coleta informações sobre você e seu uso do serviço AlphaTime, suas interações com a AlphaTime e a publicidade da AlphaTime, assim como informações sobre seu computador e outros aparelhos utilizados para acessar o serviço AlphaTime (aparelhos móveis). Tais informações incluem:
                Suas atividades no serviço AlphaTime e seu histórico de uso;
                Suas interações com nossos e-mails, nossas mensagens de texto e nossas mensagens enviadas por outros canais de comunicação, inclusive dashboard e aplicativos móveis;
                Detalhes sobre suas interações com o serviço de atendimento ao cliente, tais como data, hora e motivo do contato, transcrição de comunicação pelo bate-papo e, no caso de chamadas, o seu número de telefone e as gravações das chamadas;
                IDs ou outros identificadores únicos de aparelhos;
                Identificadores de aparelho redefiníveis (também conhecidos como identificadores de publicidade), como os incluídos em certos aparelhos móveis e tablets (consulte a seção “Cookies e publicidade na Internet” abaixo para obter mais informações);
                Características de aparelhos e software (tais como o tipo e configuração), informações sobre a conexão, estatísticas sobre visualizações de página, fonte encaminhadora (por exemplo, URLs de origem), endereço IP (que pode indicar à AlphaTime sua localização geográfica aproximada), navegador e dados padrão de logs de servidores da Internet;
                Informações coletadas pelo uso de cookies, web beacons e outras tecnologias, incluindo dados de anúncios (tais como informações sobre a disponibilidade e distribuição de anúncios, a URL do site, assim como a data e hora). (Consulte a seção “Cookies e publicidade na Internet” para obter mais detalhes.)
                Informações obtidas com parceiros: a AlphaTime coleta informações de outras empresas com as quais você mantém um relacionamento (“Parceiros”). Esses Parceiros podem incluir (dependendo dos serviços que você usa): o seu provedor de serviços de Internet, ou outros provedores de aparelhos que disponibilizam o nosso serviço no seu aparelho; operadoras de telefonia celular ou outras empresas que fornecem serviços e recebem o pagamento pelo serviço AlphaTime e o repassam para nós. As informações fornecidas pelos Parceiros para nós dependem da natureza dos serviços do Parceiro e podem incluir:
                Informações de ativação de serviço, como o seu e-mail ou outras informações de contato;
                IDs de aparelho ou outros identificadores únicos usados na autenticação de usuários, na experiência de assinatura do serviço AlphaTime, no processamento de pagamentos pelo Parceiro.
                Informações de outras fontes: a AlphaTime também obtém informações de outras fontes. Protegemos essas informações de acordo com as práticas descritas nesta Declaração de Privacidade, além de respeitar as restrições adicionais impostas pela fonte dos dados. Essas fontes variam no decorrer do tempo, mas podem incluir:
                Prestadores de serviços que nos ajudam a identificar uma localização baseada no seu endereço IP/GPS para personalizar o nosso serviço e para outros fins compatíveis com esta Declaração de privacidade;
                Prestadores de serviços de pagamento que nos fornecem informações de pagamento ou atualizações dessas informações baseadas no seu relacionamento com você;
                Fornecedores de dados online e offline, dos quais obtemos dados demográficos, dados baseados no interesse e dados relacionados a anúncios online;
                Fontes disponíveis ao público, como bancos de dados abertos do governo.

                Uso de informações

                A AlphaTime utiliza as informações para oferecer, analisar, administrar, aprimorar e personalizar nossos serviços e esforços de marketing, para processar sua inscrição, seus pedidos e pagamentos, e para nos comunicarmos com você sobre esses e outros assuntos. Por exemplo, a AlphaTime utiliza as informações para:
                Determinar sua localização geográfica aproximada e de seu(s) funcionário(s), oferecer serviços personalizados que, na nossa avaliação, poderiam ser do seu interesse, determinar o seu provedor de serviços de Internet e ajudar nossa equipe a responder de forma rápida e eficiente às suas dúvidas e solicitações;
                Coordenar com os Parceiros a disponibilização do serviço AlphaTime para os assinantes e fornecer aos não assinantes informações sobre a disponibilidade do serviço AlphaTime de acordo com a relação específica que você mantém com o Parceiro;
                Prevenir, detectar e investigar atividades possivelmente proibidas ou ilegais, incluindo atividades fraudulentas, e aplicar nossos termos (tais como determinar a sua elegibilidade para uma utilização gratuita);
                Analisar e entender nosso público, melhorar o serviço (inclusive a interface do usuário) e otimizar a seleção de conteúdo, os algoritmos de recomendação e a transmissão;
                Comunicar-se com você sobre o serviço para que possamos enviar novidades sobre a AlphaTime, detalhes sobre novas funcionalidades, serviços disponíveis na AlphaTime, ofertas especiais, anúncios sobre promoções, pesquisas de mercado, e para ajudar você com pedidos de natureza operacional, como pedidos de redefinição de senha. Essas comunicações podem ser feitas por vários métodos, tais como e-mail, notificações push, mensagens de texto, canais de mensagens online e comunicações de identificadores correspondentes (descritas abaixo). Consulte a seção “Suas opções” desta Declaração de Privacidade para obter informações sobre como configurar e modificar suas preferências de comunicação.

                Divulgação de informações

                A AlphaTime divulgará seus dados para fins específicos e a terceiros, conforme descrição abaixo:
                Grupo AlphaTime: poderemos compartilhar suas informações com as empresas AlphaTime (https://help.AlphaTime.net) conforme necessário, para: processamento e armazenamento de dados; proporcionar acesso a nossos serviços; proporcionar serviço de atendimento ao cliente; tomar decisões sobre melhorias ao serviço, desenvolvimento de conteúdo e outros fins descritos na seção “Uso de informações” da presente Declaração de Privacidade.
                Prestadores de Serviços: a AlphaTime poderá contratar outras empresas, agentes ou terceirizados (os “Prestadores de Serviços”) para fornecer serviços em nome da AlphaTime ou ajudar a AlphaTime no fornecimento de serviços destinados a você. Por exemplo, a AlphaTime contrata Prestadores de Serviços para fornecer serviços de marketing, comunicação, infraestrutura e serviços de TI, para personalizar e otimizar o serviço AlphaTime, processar transações por cartão de crédito e outras formas de pagamento, fornecer serviços de atendimento ao cliente, cobrar dívidas, analisar e aprimorar dados (inclusive dados de interação com o serviço AlphaTime) e conduzir pesquisas de mercado. No decorrer da prestação desses serviços, esses Prestadores de Serviços podem ter acesso a suas informações pessoais ou de outra natureza. Não autorizamos estas empresas a usar ou divulgar seus dados pessoais ou comerciais, a não ser com a finalidade de fornecer os serviços contratados pela AlphaTime.
                Parceiros: como descrito acima, você pode ter um relacionamento com um ou mais de nossos Parceiros. Nesse caso, poderemos compartilhar determinadas informações com esses Parceiros para coordenar com eles a prestação do serviço AlphaTime aos assinantes e fornecer informações sobre a disponibilidade do serviço AlphaTime. Por exemplo, dependendo de quais serviços de Parceiros você usa, poderemos compartilhar informações:
                Para facilitar o recebimento do pagamento do serviço AlphaTime pelo Parceiro e o repasse desse pagamento para a AlphaTime;
                Ofertas promocionais: a AlphaTime poderá oferecer programas ou promoções conjuntas que, para efeitos de participação, exijam que informações pessoais sejam compartilhadas com terceiros. Para realizar estes tipos de promoção, a AlphaTime poderá compartilhar seu nome e outras informações pessoais referentes ao benefício que estamos oferecendo. Por favor, observe que estes terceiros são responsáveis por suas próprias políticas de privacidade.
                Proteção da AlphaTime e outros: a AlphaTime e seus Prestadores de Serviços poderão divulgar ou, de outra forma, utilizar suas informações pessoais quando a AlphaTime ou tais empresas tiverem motivos razoáveis para acreditar que tal divulgação é necessária para (a) atender a alguma lei ou norma aplicável, processo legal ou solicitação governamental, (b) fazer cumprir os termos de uso aplicáveis, incluindo a investigação de potenciais infrações destes, (c) detectar, prevenir ou endereçar atividades ilegais ou suspeitas (inclusive fraude de pagamentos), problemas técnicos ou de segurança ou (d) proteger-se contra infrações aos direitos, propriedade ou segurança da AlphaTime, de seus usuários ou do público, conforme requerido ou permitido por lei.
                Transferência de propriedade: em relação a qualquer reorganização, reestruturação, fusão ou venda, ou transferência de ativos, a AlphaTime transferirá informações, incluindo informações pessoais e comerciais, desde que a parte receptora se comprometa a respeitar suas informações pessoais e comerciais de maneira condizente com nossa Declaração de Privacidade.
                Você também poderá optar por divulgar informações suas das seguintes maneiras:
                Determinadas seções do serviço AlphaTime poderão conter uma ferramenta que lhe dá a opção de compartilhar informações por e-mail, mensagem de texto ou outros aplicativos de compartilhamento por intermédio de softwares cliente e aplicativos instalados no seu aparelho;
                Plugins de redes sociais e tecnologias similares permitem o compartilhamento de informações. Plugins e aplicativos de redes sociais são operados pelas próprias redes sociais e estão sujeitos aos seus respectivos termos de uso e políticas de privacidade.
                Acesso à conta e perfis

                Para simplificar o acesso à sua conta, você pode utilizar a função “Lembre-se de mim neste aparelho” ao acessar o site ou dispositivo móvel. Essa função utiliza tecnologia que nos permite dar acesso direto à conta e ajuda a utilizar o serviço AlphaTime sem redigitar a senha ou identificação do usuário ao retornar ao serviço no navegador.

                Para excluir o acesso à sua conta AlphaTime de seus aparelhos: (a) acesse a seção “Conta” no nosso site, selecione “Encerrar a sessão em todos os aparelhos” e siga as instruções para desativar os aparelhos (a desativação poderá não ocorrer imediatamente) ou (b) remova as configurações da sua conta AlphaTime no seu aparelho (as instruções variam segundo o aparelho, e esta opção não está disponível em todos os aparelhos). Sempre que possível, assinantes que utilizam aparelhos públicos ou compartilhados devem encerrar sua sessão ao final de cada acesso. Você sempre deve encerrar sua sessão e desativar o aparelho compatível com a AlphaTime se for vender ou devolver um computador ou aparelho. Se você não mantiver a segurança da sua senha ou aparelho, ou não encerrar a sessão e desativar o seu aparelho, usuários subsequentes poderão ter acesso à sua conta e a suas informações pessoais.

                Se você compartilhar ou permitir que outras pessoas tenham acesso à sua conta, elas poderão ver suas informações (inclusive algumas informações pessoais), como seu histórico de gestão, dados de funcionário(s) classificações e informações da sua conta (inclusive seu e-mail e outros dados na seção “Conta” no nosso site). Isso ocorre mesmo utilizando o recurso de perfis.

                Os perfis permitem que os usuários tenham uma experiência AlphaTime personalizada e distinta, criada com base nos seus registros e funcionário(s), cada um com o seu próprio histórico de atividades. Os perfis estão à disposição de todos aqueles que utilizarem sua conta AlphaTime, portanto, qualquer pessoa com acesso à sua conta AlphaTime poderá acessar, utilizar, editar e excluir perfis. Explique esta funcionalidade a todas as pessoas com acesso à sua conta e, se não quiser que eles utilizem ou alterem seu perfil, certifique-se de informá-los. Os usuários do perfil podem ter a oportunidade de adicionar um e-mail, número de telefone ou outras informações ao perfil e receberão uma notificação de cobrança e uso no momento em que essas informações forem solicitadas (sujeito à Declaração de Privacidade do perfil [https://help.AlphaTime.net ]).

                Suas opções
                Mensagens de e-mail e texto. Se você não quiser mais receber determinados comunicados da AlphaTime por e-mail ou mensagens de texto, acesse a opção “Configurações de comunicação” na seção “Conta” no nosso site e desmarque as opções que deseja cancelar. Você pode também clicar no link “cancelar” no e-mail recebido, ou responder “STOP” (exceto se receber instruções diferentes) à mensagem de texto. Observe que você não pode cancelar o recebimento de comunicados da AlphaTime relacionados ao serviço, incluindo mensagens referentes às transações da sua conta.
                Notificações push. Você pode optar por receber notificações push da AlphaTime. Em alguns sistemas operacionais de aparelhos, você será inscrito automaticamente para receber as notificações. Caso decida posteriormente que já não deseja receber essas notificações, você poderá utilizar as configurações do seu aparelho móvel para desativá-las.
                WhatsApp. Se você ativou as mensagens do WhatsApp relacionadas com a sua conta ou perfil AlphaTime e não quiser mais recebê-las, pode usar as definições do aplicativo WhatsApp para bloqueá-las.
                Anúncios baseados em interesse. Os anúncios baseados em interesse são anúncios online direcionados aos seus prováveis interesses com base na sua utilização de diversos aplicativos e sites na Internet. Em um navegador, cookies e web beacons podem ser utilizados para coletar informações que ajudam a identificar os seus prováveis interesses. Se estiver utilizando um aparelho móvel ou tablet que inclua um identificador de aparelho redefinível, este poderá ser utilizado para ajudar a identificar os seus prováveis interesses. Para verificar quais são as suas opções referentes a anúncios baseados em interesse na AlphaTime, consulte a seção “Cookies e publicidade na Internet” (abaixo).
                Comunicações de identificadores correspondentes. Alguns sites e aplicativos de terceiros nos permitem atingir nossos usuários com promoções online sobre serviços por meio do envio de um identificador que protege a privacidade para o terceiro. Um identificador que protege a privacidade significa que convertemos as informações originais (como um e-mail ou número de telefone) em um valor para evitar que as informações originais sejam reveladas. O terceiro compara o identificador que protege a privacidade com os identificadores em seu banco de dados. Somente ocorrerá uma correspondência se você tiver usado o mesmo identificador (como um e-mail) com a AlphaTime e com o terceiro. Se houver uma correspondência, a AlphaTime poderá optar por enviar uma determinada comunicação promocional para você nesse site ou aplicativo de terceiros. Você pode optar por não receber essas comunicações na seção “Comunicações de marketing” da seção “Conta” no nosso site.

                Suas informações e direitos
                Você pode solicitar acesso às suas informações pessoais, bem como pode corrigir ou atualizar informações pessoais desatualizadas ou incorretas que temos sobre você.
                Para isso, basta visitar a seção “Conta” no nosso site, onde você pode acessar e atualizar uma série de informações sobre sua conta, inclusive suas informações de contato, de pagamento e diversas outras relacionadas à sua conta (como os serviços contratados na assinatura). Será preciso iniciar uma sessão para acessar a seção “Conta”. Você também pode solicitar que a AlphaTime exclua suas informações pessoais.
                Para fazer essas solicitações ou caso você tenha qualquer outra dúvida sobre nossa política de privacidade, escreva para o Diretor de Proteção de Dados/Divisão de Privacidade por e-mail no endereço privacy@AlphaTime.net. Respondemos a todas as solicitações que recebemos de indivíduos que queiram exercer os seus direitos de proteção de dados em conformidade com as respectivas leis de proteção de dados. Consulte também a seção “Suas opções” desta Declaração de privacidade para ver quais são suas outras opções relativas às suas informações.
                A AlphaTime pode rejeitar solicitações que sejam desarrazoadas ou não exigidas por lei, como aquelas que sejam extremamente impraticáveis, capazes de exigir um esforço técnico desproporcional ou que possa nos expor a riscos operacionais, como fraude em relação ao período de utilização gratuita. A AlphaTime pode reter informações conforme exigência ou permissão prevista em leis e regulamentos aplicáveis, inclusive para honrar suas escolhas, para fins de cobrança ou registros e para atender às finalidades descritas nesta Declaração de privacidade. A AlphaTime toma medidas razoáveis para destruir ou desidentificar informações pessoais de forma segura quando deixam de ser necessárias.
                Segurança
                A AlphaTime emprega medidas administrativas, lógicas, gerenciais e físicas razoáveis para proteger suas informações pessoais e comerciais contra perdas, roubos e acesso, uso e alterações não autorizados. Essas medidas são elaboradas para oferecer um nível de segurança adequado aos riscos de processamento de suas informações pessoais.
                Outros sites, plataformas e aplicativos
                O serviço AlphaTime pode ser fornecido por plataformas de terceiros e/ou utiliza recursos operados por plataformas de terceiros, bem como pode conter links para sites de terceiros, cujas políticas relativas ao tratamento de informações podem diferir das nossas. Por exemplo, você pode acessar o serviço AlphaTime por meio de plataformas como aparelhos móveis com conexão à Internet. Esses sites e plataformas têm políticas de privacidade e dados, Declarações de Privacidades, termos e avisos de uso separados e independentes, e recomendamos que você os leia atentamente. Além disso, você pode encontrar aplicativos de terceiros que interajam com o serviço AlphaTime.

                Crianças
                Você deve ter 18 anos ou mais para assinar o serviço AlphaTime. Em algumas jurisdições, a maioridade pode ser acima de 18 anos e, neste caso, você deve atender aos requisitos de idade para assinar o serviço AlphaTime. Apesar de indivíduos com menos de 18 anos poderem utilizar o serviço, eles só poderão fazê-lo com o envolvimento, a supervisão e aprovação dos pais ou responsáveis.

                Alterações na presente Declaração de Privacidade
                A AlphaTime poderá periodicamente alterar esta Declaração de privacidade para atender a mudanças na legislação, exigências regulatórias ou operacionais. Comunicaremos qualquer alteração (inclusive a data em que as mesmas entrarão em vigor) conforme previsto por lei. O uso continuado por você do serviço AlphaTime depois que tais alterações passem a vigorar constituirá seu reconhecimento e (conforme o caso) a sua aceitação das mesmas. Caso você não queira reconhecer ou aceitar nenhuma das atualizações desta Declaração de privacidade, você poderá cancelar o serviço AlphaTime. Para saber quando essa Declaração de privacidade foi atualizada pela última vez, consulte a seção “Última atualização”, abaixo.
                Cookies e publicidade na Internet
                A AlphaTime e os nossos Prestadores de Serviços contratados utilizam cookies e outras tecnologias (tais como web beacons), assim como identificadores de aparelho redefiníveis, por diversos motivos. Por exemplo, utilizamos essas tecnologias para facilitar o seu acesso a nossos serviços, permitindo-nos reconhecer você a cada vez que você volta, e para fornecer e analisar nossos serviços. Também utilizamos cookies e identificadores de aparelho redefiníveis para aprender mais sobre nossos usuários e seus prováveis interesses e para personalizar e enviar mensagens de marketing ou publicidade. Nós queremos informá-lo do nosso uso dessas tecnologias. Assim, esta seção explica quais tipos de tecnologias utilizamos, sua função e suas opções em relação ao uso das mesmas.

                O que são cookies?

                Cookies são pequenos arquivos de dados, armazenados normalmente no seu aparelho enquanto você navega e utiliza sites e serviços online. Cookies são bastante utilizados para que sites funcionem ou funcionem de forma mais eficiente, bem como para gerar informações de relatórios e ajudar na personalização do serviço ou publicidade.

                Os cookies não são os únicos tipos de tecnologias que permitem essa funcionalidade. Também usamos outros tipos de tecnologias semelhantes. Consulte os itens abaixo para obter mais informações e exemplos.

                O que são identificadores de aparelho redefiníveis?

                Os identificadores de aparelho redefiníveis (também conhecidos como identificadores de publicidade) são similares aos cookies e se encontram em muitos aparelhos móveis e tablets (por exemplo, o “Identificador de Publicidade” [ou IDFA] nos aparelhos Apple iOS e o “Código de publicidade da Google” em aparelhos Android). Assim como os cookies, os identificadores de aparelho redefiníveis são utilizados para aumentar a relevância de anúncios online.

                Por que a AlphaTime usa cookies e identificadores de aparelho redefiníveis?
                Cookies essenciais: esses cookies são estritamente necessários para fornecer nosso serviço online ou website. Por exemplo, nós e nossos Prestadores de Serviços podemos usar esses cookies para autenticar e identificar nossos assinantes quando eles usam nossos sites e aplicativos e possamos fornecer serviços a eles. Os cookies também nos ajudam a garantir o cumprimento de nossos Termos de uso, prevenir fraudes e manter a segurança do nosso serviço.
                Cookies de desempenho e funcionalidade: esses cookies não são essenciais, mas nos ajudam a personalizar e aprimorar a sua experiência online com a AlphaTime. Por exemplo, eles nos ajudam a lembrar de suas preferências e evitar que você precise digitar informações que você já forneceu (por exemplo, durante a inscrição). Também usamos esses cookies para coletar informações (como páginas populares, taxas de conversão, padrões de visualização, número de cliques e outras informações) sobre o uso do serviço AlphaTime por nossos visitantes, para que possamos aprimorar e personalizar nosso site e nosso serviço e fazer pesquisas de mercado. A exclusão desses tipos de cookies poderá resultar em funcionalidade limitada do nosso serviço.
                Cookies de publicidade e identificadores de aparelho redefiníveis: esses cookies e identificadores de aparelho redefiníveis usam informações sobre a sua utilização deste e de outros sites e aplicativos, assim como sua resposta a anúncios e e-mails, para exibir anúncios mais relevantes a você. Esses tipos de anúncios são chamados de “anúncios baseados em interesse”. Muitos dos cookies de publicidade associados ao nosso serviço pertencem aos nossos Prestadores de Serviços.

                Como eu posso fazer escolhas em relação a cookies e identificadores de aparelho reconfiguráveis?

                Para optar por não receber da AlphaTime anúncios baseados em interesse exibidos em conexão com um identificador de aparelho redefinível em um aparelho móvel ou tablet, configure a respectiva opção no seu aparelho (geralmente em “privacidade” ou “anúncios” nas configurações do aparelho). É possível que continue a ver anúncios da AlphaTime no seu aparelho, mas não serão personalizados de acordo com os seus prováveis interesses.

                Como a AlphaTime usa web beacons e outras tecnologias?

                Os web beacons (também conhecidos como clear gifs ou pixel tags) normalmente funcionam em conjunto com os cookies. Nós e nossos Prestadores de Serviços podemos usá-los para fins semelhantes aos dos cookies, como para entender e aprimorar o uso do nosso serviço, melhorar o desempenho do site, monitorar as ações e o tráfego de visitantes no nosso site e entender as interações com o nosso marketing (incluindo e-mails e anúncios online em sites de terceiros). Como os web beacons normalmente funcionam em conjunto com os cookies, em vários casos, rejeitar os cookies impedirá também a eficácia dos web beacons associados a esses cookies.

                Usamos outras tecnologias semelhantes a cookies, como armazenamento de navegador e plugins (por exemplo, HTML5, IndexedDB e WebSQL). Como os cookies, algumas dessas tecnologias podem armazenar pequenas quantidades de dados em seu aparelho. Podemos usar essas e outras tecnologias para fins semelhantes aos dos cookies, como para fazer valer os nossos termos, evitar fraude e analisar o uso do serviço. Existem várias formas de exercer as suas escolhas em relação a essas tecnologias. Por exemplo, vários navegadores comuns fornecem a capacidade de eliminar o armazenamento do navegador, normalmente localizado na área de configurações ou preferências. Consulte a função de ajuda ou suporte do seu navegador para saber mais. Outras tecnologias, como armazenamento Silverlight, podem ser excluídas de dentro do aplicativo.

                Última atualização: 01 de fevereiro de 2022.

                <ng-template pTemplate="footer">
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-success btn-sm w-50" (click)="showModalTerms=false">
                            OK
                        </button>
                    </div>
                </ng-template>
            </p-dialog>
        </div>
    </div>
</div>
