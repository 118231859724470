<img src="./assets/img/mockup2.png" alt="" class="imagem">
<div class="conteudo">
    <img src="./assets/img/logo-alphatime.png" alt="" class="logo">
    <h1> Dúvidas na Assinatura?</h1>
    <p>
        Percebemos que você teve dificuldades em escolher sua assinatura Alphatime, a ferramenta mais moderna para
        gestão da sua equipe de trabalho. Nosso time comercial ficará feliz em te auxiliar, de forma fácil e rápido,
        através de um contato em nosso site:<a href="https://www.alphatime.net" target="_blank">https://www.alphatime.net</a>,
        no acesso via WhatsApp. Ou se preferir podemos te ligar, basta você deixar seus dados para o contato no site.
        Aproveitamos para lembrar que o acesso para três funcionários é gratuito e você já pode degustar nossas
        funcionalidades neste acesso, basta escolher o plano Gratuito e pronto! Aguardamos seu contato.
    </p>
</div>
