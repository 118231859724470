<p-toast></p-toast>
<img src="./assets/img/mockup2.png" alt="" class="imagem">
<div class="conteudo">
    <img src="./assets/img/logo-alphatime.png" alt="" class="logo">
    <h1>Olá, Precisamos de algumas informações para prepararmos uma experiência direcionada à
        necessidade de sua empresa e, em breve mantermos contato.
    </h1>
    <form [formGroup]="contactForm" (ngSubmit)="submit()">
        <div class="form-group">
            <input type="text" placeholder="Nome" [readonly]="true" value="{{employee?.name}}">
        </div>
        <div class="form-group">
            <input type="email" placeholder="E-mail" [readonly]="true" value="{{employee?.email}}">
        </div>
        <div class="form-group">
            <input type="email" placeholder="Telefone" [readonly]="true" value="{{employee?.phone}}">
        </div>
        <div class="form-group">
            <input type="text" placeholder="Nome da Empresa" [readonly]="true" value="{{employee?.companyName}}">
        </div>
        <div class="form-group">
            <textarea type="text" placeholder="Mensagem" formControlName="message" rows="6"></textarea>
            <div class="invalid-feedback" *ngIf="contactForm.get('message')?.hasError('required')
                                               && contactForm.get('message')?.touched">
                Mensagem é obrigatória
            </div>
            <div class="invalid-feedback" *ngIf="contactForm.get('message')?.hasError('maxlength')
                                                   && contactForm.controls['message']?.touched">
                O tamanho da mensagem deve ter no máximo 120 caracteres
            </div>
        </div>
        <div class="form-group">
            <button type="submit" [disabled]="!contactForm.valid">
                <div *ngIf="loading" class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="sr-only">...</span>
                </div>
                <span *ngIf="!loading">Enviar</span>
            </button>
        </div>
    </form>
</div>

