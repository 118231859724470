import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {AuthService} from '../../services/auth.service';
import {CompanyService} from '../../services/company.service';
import {NotificationService} from '../../services/notification.service';
import {Router} from '@angular/router';
import {RECAPTCHA} from '../../environments/environment';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

    public contactForm: FormGroup;
    public loading = false;
    public employee: any | undefined;

    constructor(
        private toastMessage: MessageService,
        private authService: AuthService,
        private companyService: CompanyService,
        private formBuilder: FormBuilder,
        private router: Router,
        private notificationService: NotificationService) {

        const data: string | null = localStorage.getItem('SIGNUP_EMPLOYEE');

        if (typeof data !== 'string') {
             this.router.navigate(['/']).then(() => 'flow not authorized');
        } else {
            this.employee = JSON.parse(data);
        }

        this.contactForm = this.formBuilder.group({
            message: ['', [Validators.required, Validators.maxLength(500)]]
        });

    }

    public async submit(): Promise<void> {
        this.loading = true;
        grecaptcha.ready(() => {
            grecaptcha.execute(RECAPTCHA.v3, { action: 'signup_contact'}).then(async (token: any) => {

                const {
                    message,
                } = this.contactForm.value;

                const {
                    name,
                    email,
                    phone,
                    companyName,
                } = this.employee;

                try {
                    await this.notificationService.sendContact({
                        name,
                        email,
                        phone,
                        message,
                        companyName,
                        token
                    }).toPromise();

                    await this.router.navigate(['/welcome']);
                    this.loading = false;
                } catch (e: any) {
                    this.loading = false;
                    this.toastMessage.add({
                        severity: 'error',
                        summary: 'Problema no envio',
                        detail: 'Verifique as informações fornecidas ou se o problema persistir contacte a Alphatime'
                    });
                }
            });
        });
    }

}
