import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegisterComponent} from './register/register.component';
import {ProductComponent} from './product/product.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {RegisterLayoutComponent} from '../layout/register/register-layout.component';
import {ProductLayoutComponent} from '../layout/product/product-layout.component';
import {ContactComponent} from './contact/contact.component';
import {CanceledComponent} from './canceled/canceled.component';

const routes: Routes = [
    {
        path: 'products',
        component: ProductLayoutComponent,
        children: [{
            path: '',
            component: ProductComponent
        }]
    },
    {
        path: '',
        component: RegisterLayoutComponent,
        children: [{
            path: '',
            component: RegisterComponent
        },
            {
                path: 'checkout',
                component: CheckoutComponent
            },
            {
                path: 'welcome',
                component: WelcomeComponent,
            },
            {
                path: 'canceled',
                component: CanceledComponent
            },
            {
                path: 'contact',
                component: ContactComponent
            }]

    },
    {path: '**', redirectTo: '/register'}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
