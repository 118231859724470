import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_URL} from '../environments/environment';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private httpClient: HttpClient) {
  }

  public createCheckoutSession(priceId: string,  customerEmail: string): Observable<any> {
    const successUrl = `${window.location.origin}/welcome?session_id={CHECKOUT_SESSION_ID}'`;
    const cancelUrl = `${window.location.origin}/products`;

    const body = JSON.stringify({ priceId, successUrl, cancelUrl, customerEmail });
    return this.httpClient.post(`${SERVER_URL}/payment/create-checkout-session/`, body);
  }

  public async redirectToCheckout(sessionId: string): Promise<any> {
    return stripe.redirectToCheckout({ sessionId } );
  }

}
