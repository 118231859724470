import {Component, OnInit} from '@angular/core';
import {PaymentService} from '../../services/payment.service';
import {PRODUCTS} from '../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {Employee} from '../../model/employee';
import {AuthService} from '../../services/auth.service';
import {EmployeeService} from '../../services/employee.service';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

    private employee: Employee | undefined;
    public loading = false;
    public plan = '';

    constructor(
        private authService: AuthService,
        private employeeService: EmployeeService,
        private route: ActivatedRoute,
        private router: Router,
        private paymentService: PaymentService) {
    }

    async ngOnInit(): Promise<any> {

        this.route.queryParams.subscribe(async params => {
            if (params.hasOwnProperty('key')) {
                const jwt = params.key;
                const email = this.authService.getTokenEmail(jwt);
                this.employee = (await this.employeeService.findByEmail(email, jwt).toPromise()).data;
            }
        });
    }

    public async goToCheckout(plan: string, period: string): Promise<any> {
        this.loading = true;
        if (!this.employee) {
            const data: string | null = localStorage.getItem('SIGNUP_EMPLOYEE');
            if (typeof data === 'string') {
                this.employee = JSON.parse(data);
            }
        }

        if (this.employee && this.employee.email) {
            // await this.router.navigate(['/checkout'], { queryParams: { plan, value }});
            // this.loading = false;
            //@ts-ignore
            const priceId: any = PRODUCTS[period][plan];
            this.paymentService.createCheckoutSession(priceId, this.employee.email)
                .subscribe((sessioId) => {
                    // @ts-ignore
                    this.paymentService.redirectToCheckout(sessioId.data).then((result) => {
                        this.loading = false;
                    });
                });
        }

    }

    public async goToContactForm(): Promise<void> {
        await this.router.navigate(['/contact']);
    }

    public async goToWelcome(): Promise<void> {
        await this.router.navigate(['/welcome']);
    }
}
