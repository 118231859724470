
export const environment = {
  production: true,
  staging: false
};

export const COGNITO = {
  identityPoolId: 'us-east-1:9084ba56-63f2-43b8-af9c-4c1b44247b21',
  region: 'us-east-1',
  userPoolId: 'us-east-1_ojSfYn7gi',
  userPoolWebClientId: '1nj2rthpnnqjgcm013ch1n7qh9',
};

export const RECAPTCHA = {
  siteKey: '6LfAeVMdAAAAAAWuyKFmKDJ6F5ZGc_JBNtC7lLZP',
  v3: '6LcZFlMdAAAAADuddzcsiXrwzKdT7E0tY4EGSC2D'
};

export const API_NAME = 'ALPHATIME_API';
export const STRIPE_API = 'pk_live_crLLURS5YUEYDsxESoN9CyAG00NBVWgVft';
export const SERVER_URL = 'https://wg1loo6vwg.execute-api.us-east-1.amazonaws.com/master';
export const EMPLOYER_IMG_URL = 'https://d1d0z677jvat3n.cloudfront.net/public/';

export const PRODUCTS = {
  MENSAL: {
    STUP: 'price_1LGBtILmDyW1w19xYJSg7dCj',
    PE: 'price_1N6X5QLmDyW1w19xFWSMVng4',
    ME1: 'price_1N6X0KLmDyW1w19xLBUuxAjc',
    ME2: 'price_1LGBwKLmDyW1w19xoglu7GtF'
  },
  ANUAL: {
    STUP: 'price_1LGBtILmDyW1w19xovfgOz6m',
    PE: 'price_1LGBuILmDyW1w19xwLo5N9OE',
    ME1: 'price_1LGBv7LmDyW1w19x3hrs1r5U',
    ME2: 'price_1LGBwKLmDyW1w19x887mwgr2'
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
