import {Component, isDevMode, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {CompanyService} from '../../services/company.service';
import {MessageService} from 'primeng/api';
import {EmailValidation, RegisterIdValidator, removeAnySpaces, removePunctuation, RepeatPasswordValidator, TermsAcceptedValidator} from '../app.validators';
import {environment, RECAPTCHA} from '../../environments/environment';
import {FakerService} from '../../services/faker.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    providers: [MessageService]
})
export class RegisterComponent {

    public formRegister: FormGroup;
    public showHidePassword = true;
    public showHideConfirmPassword = true;
    public loading = false;
    public areaCode = '+55';
    public checked = false;
    public showModalTerms = false;

    constructor(
        private toastMessage: MessageService,
        private authService: AuthService,
        private companyService: CompanyService,
        private formBuilder: FormBuilder,
        private router: Router,
        private faker: FakerService) {

        this.formRegister = this.formBuilder.group({
            name: ['', Validators.required],
            cnpj: ['', Validators.required],
            phone: ['', [Validators.required, Validators.minLength(10)]],
            email: ['', EmailValidation],
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(24)]],
            confirmPassword: ['', Validators.required],
            termsAccepted: ['', Validators.required],
            companyName: ['', [Validators.required, Validators.maxLength(120)]],
        }, {validator: [RepeatPasswordValidator, RegisterIdValidator, TermsAcceptedValidator]});
    }

    public async register(): Promise<void> {
        this.loading = true;
        grecaptcha.ready(() => {
            grecaptcha.execute(RECAPTCHA.v3, {action: 'signup'}).then(async (token: any) => {
                const {
                    name,
                    email,
                    phone,
                    cnpj,
                    password,
                    companyName
                } = this.formRegister.value;

                const employee = {
                    name,
                    email: email.toLowerCase(),
                    password,
                    phone: phone.replace(/\D/g, '')
                };

                const company = {
                    name: companyName,
                    cnpj: removeAnySpaces(removePunctuation(cnpj))
                };

                try {
                    await this.companyService.signUp({employee, company, token}).toPromise();
                    localStorage.setItem('SIGNUP_EMPLOYEE', JSON.stringify({
                        name,
                        email: email.toLowerCase(),
                        cnpj,
                        companyName,
                        password,
                        phone: phone.replace(/\D/g, '')
                    }));
                    await this.router.navigate(['/welcome']);
                    this.loading = false;
                } catch (e: any) {
                    this.loading = false;
                    if (e.error.message === 'EMAIL_IN_USE') {
                        this.toastMessage.add({severity: 'error', summary: 'Problema no registro', detail: 'E-mail já está em uso'});
                    } else if (e.error.message === 'PHONE_IN_USE') {
                        this.toastMessage.add({
                            severity: 'error',
                            summary: 'Problema no registro',
                            detail: 'Número telefonico já está em uso!'
                        });
                    } else if (e.error.message === 'CNPJ_IN_USE') {
                        this.toastMessage.add({severity: 'error', summary: 'Problema no registro', detail: 'CNPJ em uso'});
                    } else {
                        this.toastMessage.add({
                            severity: 'error',
                            summary: 'Problema no registro',
                            detail: 'Verifique as informações fornecidas ou se o problema persistir contacte a Alphatime'
                        });
                    }
                }
            });
        });
    }

    public gotoDashboardLogin(): void {
        if (environment.production) {
            window.open('https://dashboard.alphatime.net', '_blank');
        } else {
            window.open('https://qadashboard.alphatime.net/dashboard', '_blank');
        }
    }

    public async goToPrivacyPolicy(): Promise<void> {
        await this.router.navigate(['/privacy-policy']);
    }

    public async goToTermsCondition(): Promise<void> {
        await this.router.navigate(['/terms-condition']);
    }

    public fillForm(): void {

        if (isDevMode()) {

            const firstName = this.faker.firstName();
            const lastName = this.faker.lastName();
            const name = firstName + ' ' + lastName;

            const values = {
                name,
                cnpj: this.faker.cnpj(),
                phone: this.faker.createPhoneNumber(),
                email: `${firstName}@test.com`.toLowerCase(),
                password: '12345678',
                confirmPassword: '12345678',
                companyName: `Company of ${lastName}`,
                termsAccepted: null
            };


            this.formRegister.setValue(values);
        }

    }
    public handleShowModalTerms() : void{
        this.showModalTerms = true;
    }

}
