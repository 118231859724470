<img src="./assets/img/imagem-c.png" class="imagem-c" alt="">
<img src="./assets/img/imagem-a.png" class="imagem-a" alt="">
<img src="./assets/img/imagem-b.png" class="imagem-b" alt="">
<img src="./assets/img/logo-descrition.png" class="logo-descrition" alt="">
<img src="./assets/img/imagem-d.png" class="imagem-d" alt="">

<div class="d-flex justify-content-end align-items-center m-0 w-100 h-100">
    <div class="conteudo">
        <div>
            <h1 style="color: #33FF99;">Confirmação da Assinatura</h1>
        </div>
        <div>
            <p class="text-light">
              Parabéns! Sua assinatura foi realizada com sucesso e a partir de agora você terá na sua empresa a ferramenta mais
              moderna de gestão da sua equipe de trabalho. Mais informações serão enviadas ao seu e-mail cadastrado.
              Caso tenha alguma dúvida ou necessite de suporte, nos contate nos canais de comunicação em nosso site:
              <a href="https://www.alphatime.net" style="color: #33FF99;" target="_blank">https://www.alphatime.net</a>
            </p>
        </div>
    </div>
</div>
