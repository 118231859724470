<div class="progress-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="lista">
    <div class="item">
        <div class="box">
            <div class="cabecalho"><strong>Grátis</strong></div>
            <div class="preco"><span>R$ 00,00</span></div>
            <div class="lista">
                <ul>
                    <li>
                        <i class="fas fa-check"></i>
                        Até 3 usuários
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Aplicativo Mobile
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acesso ao Dashboard
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Controle da jornada de trabalho
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Pontos ilimitados
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Histórico diário
                    </li>
                    <li>
                        <i class="fas fa-times"></i>
                        Relatórios por períodos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Justificativa de faltas
                    </li>
                    <li>
                        <i class="fas fa-times"></i>
                        Função Home Office
                    </li>
                    <li>
                        <i class="fas fa-times"></i>
                        Função jornada híbrida
                    </li>
                    <li>
                        <i class="fas fa-times"></i>
                        Banco de horas
                    </li>
                    <li>
                        <i class="fas fa-times"></i>
                        Função férias
                    </li>
                    <li>
                        <i class="fas fa-times"></i>
                        Acompanhamento via mapa
                    </li>
                    <li>
                        <i class="fas fa-times"></i>
                        Envio de documentos
                    </li>
                    <li>
                        <i class="fas fa-times"></i>
                        Dados detalhados
                    </li>
                </ul>
            </div>
            <div class="assinatura cursor">
                <a (click)="goToWelcome()">Assinar<i class="fas fa-check"></i></a>
            </div>
        </div>
        <div class="extra">&nbsp;</div>
    </div>
    <div class="item destaque">
        <div class="box">
            <div class="nome">
                <h4>Startup</h4>
                <span>O mais escolhido</span>
            </div>
            <div class="preco"><span>R$ 55,00</span></div>
            <div class="lista">
                <ul>

                    <li>
                        <i class="fas fa-check"></i>
                        Até 10 usuários
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Aplicativo Mobile
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acesso ao Dashboard
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Controle da jornada de trabalho
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Pontos ilimitados
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Histórico diário
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Relatórios por períodos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Justificativa de faltas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Justificativa de faltas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função jornada híbrida
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Banco de horas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função férias
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acompanhamento via mapa
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Envio de documentos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Dados detalhados
                    </li>
                </ul>
            </div>
            <div class="assinatura cursor">
                <a (click)="goToCheckout('STUP', 'MENSAL')">
                    Assinar <i class="fas fa-check" ></i>
                </a>
            </div>
        </div>
        <div class="extra cursor">
            <span>Garanta<a (click)="goToCheckout('STUP', 'ANUAL')"><b> 30% de desconto</b></a>no plano anual.</span>
        </div>
    </div>
    <div class="item">
        <div class="box">
            <div class="cabecalho"><strong>Microempresa I</strong></div>
            <div class="preco"><span>R$ 120,00</span></div>
            <div class="lista">
                <ul>

                    <li>
                        <i class="fas fa-check"></i>
                        Até 25 usuários
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Aplicativo Mobile
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acesso ao Dashboard
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Controle da jornada de trabalho
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Pontos ilimitados
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Histórico diário
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Relatórios por períodos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Justificativa de faltas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função Home Office
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função jornada híbrida
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Banco de horas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função férias
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acompanhamento via mapa
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Envio de documentos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Dados detalhados
                    </li>
                </ul>
            </div>
            <div class="assinatura cursor">
                <a (click)="goToCheckout('ME1', 'MENSAL')">
                    Assinar <i class="fas fa-check" ></i>
                </a>
            </div>
        </div>
        <div class="extra cursor">
            <span>Garanta <b><a (click)="goToCheckout('ME1', 'ANUAL')">30% de desconto</a></b> no plano anual.</span>
        </div>
    </div>
    <div class="item">
        <div class="box">
            <div class="cabecalho">
                <strong>Microempresa II</strong>
            </div>
            <div class="preco">
                <span>R$ 210,00</span>
            </div>
            <div class="lista">
                <ul>

                    <li>
                        <i class="fas fa-check"></i>
                        Até 50 usuários
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Aplicativo Mobile
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acesso ao Dashboard
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Controle da jornada de trabalho
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Pontos ilimitados
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Histórico diário
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Relatórios por períodos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Justificativa de faltas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função Home Office
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função jornada híbrida
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Banco de horas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função férias
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acompanhamento via mapa
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Envio de documentos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Dados detalhados
                    </li>
                </ul>
            </div>
            <div class="assinatura cursor">
                <a (click)="goToCheckout('ME2', 'MENSAL')">
                    Assinar <i class="fas fa-check" ></i>
                </a>
            </div>
        </div>
        <div class="extra cursor">
            <span>Garanta <b><a (click)="goToCheckout('ME2', 'ANUAL')">30% de desconto</a></b> no plano anual.</span>
        </div>
    </div>
    <div class="item">
        <div class="box">
            <div class="cabecalho">
                <strong>Pequena Empresa</strong>
            </div>
            <div class="preco">
                <span>R$ 350,00</span>
            </div>
            <div class="lista">
                <ul>

                    <li>
                        <i class="fas fa-check"></i>
                        Até 100 usuários
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Aplicativo Mobile
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acesso ao Dashboard
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Controle da jornada de trabalho
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Pontos ilimitados
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Histórico diário
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Relatórios por períodos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Justificativa de faltas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função Home Office
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função jornada híbrida
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Banco de horas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função férias
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acompanhamento via mapa
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Envio de documentos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Dados detalhados
                    </li>
                </ul>
            </div>
            <div class="assinatura cursor">
                <a (click)="goToCheckout('PE', 'MENSAL')">Assinar<i class="fas fa-check"></i></a>
            </div>
        </div>
        <div class="extra cursor">
            <span>Garanta <b><a (click)="goToCheckout('PE', 'ANUAL')">30% de desconto</a></b> no plano anual.</span>
        </div>
    </div>
    <div class="item">
        <div class="box">
            <div class="cabecalho">
                <strong>Sob Consulta</strong>
            </div>
            <div class="preco">
                <span>&nbsp;</span>
            </div>
            <div class="lista">
                <ul>

                    <li>
                        <i class="fas fa-check"></i>
                        Mais de 100 usuários
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Aplicativo Mobile
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acesso ao Dashboard
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Controle da jornada de trabalho
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Pontos ilimitados
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Histórico diário
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Relatórios por períodos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Justificativa de faltas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função Home Office
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função jornada híbrida
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Banco de horas
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Função férias
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Acompanhamento via mapa
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Envio de documentos
                    </li>
                    <li>
                        <i class="fas fa-check"></i>
                        Dados detalhados
                    </li>
                </ul>
            </div>
            <div class="assinatura cursor">
                <a (click)="goToContactForm()">Assinar<i class="fas fa-check"></i></a>
            </div>
        </div>
        <div class="extra">
            <span>&nbsp;</span>
        </div>
    </div>
</div>
