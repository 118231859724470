import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ProductLayoutComponent} from './product/product-layout.component';
import {RegisterLayoutComponent} from './register/register-layout.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    exports: [ ProductLayoutComponent, RegisterLayoutComponent],
    declarations: [ ProductLayoutComponent, RegisterLayoutComponent]
})
export class LayoutModule {}
