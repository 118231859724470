import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {SERVER_URL} from '../environments/environment';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private httpClient: HttpClient) {
  }

  /**
   *  Company Register
   *
   */
  public signUp(data: any): Observable<any> {
    return this.httpClient.post(`${SERVER_URL}/companies/sign-up`, data);
  }

}
